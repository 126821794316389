@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~swiper/scss";
@import "~swiper/scss/pagination";
@import "~swiper/scss/navigation";
@import "~swiper/scss/effect-cards";
@import "~swiper/scss/effect-coverflow";
@import "~swiper/scss/effect-creative";
@import "~swiper/scss/effect-cube";
@import "~swiper/scss/effect-fade";
@import "~swiper/scss/effect-flip";

/* =========================== CSS Global =========================== */

// Fontes -------------------------------------------------------------------------------------------------
@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/RobotoRegular.eot");
    src: url("./assets/fonts/RobotoRegular.eot?#iefix")
        format("embedded-opentype"),
    url("./assets/fonts/RobotoRegular.svg#RobotoRegular") format("svg"),
    url("./assets/fonts/RobotoRegular.ttf") format("truetype"),
    url("./assets/fonts/RobotoRegular.woff") format("woff"),
    url("./assets/fonts/RobotoRegular.woff2") format("woff2");
}
@font-face {
    font-family: "Univers";
    src: url("./assets/fonts/UniversCondensed.eot");
    src: url("./assets/fonts/UniversCondensed.eot?#iefix")
        format("embedded-opentype"),
    url("./assets/fonts/UniversCondensed.svg#UniversCondensed") format("svg"),
    url("./assets/fonts/UniversCondensed.ttf") format("truetype"),
    url("./assets/fonts/UniversCondensed.woff") format("woff"),
    url("./assets/fonts/UniversCondensed.woff2") format("woff2");
}
@font-face {
    font-family: "ZonaPro-Bold";
    src: url("./assets/fonts/ZonaPro-Bold.eot");
    src: url("./assets/fonts/ZonaPro-Bold.eot?#iefix")
        format("embedded-opentype"),
    url("./assets/fonts/ZonaPro-Bold.svg#ZonaPro-Bold") format("svg"),
    url("./assets/fonts/ZonaPro-Bold.ttf") format("truetype"),
    url("./assets/fonts/ZonaPro-Bold.woff") format("woff"),
    url("./assets/fonts/ZonaPro-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "ZonaPro-ExtraLight";
    src: url("./assets/fonts/ZonaPro-ExtraLight.eot");
    src: url("./assets/fonts/ZonaPro-ExtraLight.eot?#iefix")
        format("embedded-opentype"),
    url("./assets/fonts/ZonaPro-ExtraLight.svg#ZonaPro-ExtraLight") format("svg"),
    url("./assets/fonts/ZonaPro-ExtraLight.ttf") format("truetype"),
    url("./assets/fonts/ZonaPro-ExtraLight.woff") format("woff"),
    url("./assets/fonts/ZonaPro-ExtraLight.woff2") format("woff2");
}

.font1 { font-family: "Univers"; }
.font2 { font-family: "Roboto"; }
.font3 { font-family: "ZonaPro-Bold"; }
.font4 { font-family: "ZonaPro-ExtraLight"; }

// Cores e Backgrounds -------------------------------------------------------------------------------
.white  { color: #ffffff; }
.black  { color: #000000; }
.color1 { color: #3C3C3C; }
.color2 { color: #FDBA12; }
.color3 { color: #A8ACB9; }
.color4 { color: #CFD0D3; }
.color5 { color: #797979; }
.red    { color: #ca2026; }
.green  { color: #28a745; }
.gray58 { color: #585858 }
.white_hover:hover  { color: #ffffff; }
.black_hover:hover  { color: #000000; }
.color1_hover:hover { color: #3C3C3C; }
.color2_hover:hover { color: #FDBA12; }
.color3_hover:hover { color: #A8ACB9; }
.color4_hover:hover { color: #CFD0D3; }

.bg_none   { background: none; }
.bg_white  { background: #ffffff; }
.bg_black  { background: #000000; }
.bg_color1 { background: #3C3C3C; }
.bg_color2 { background: #FDBA12; }
.bg_color3 { background: #A8ACB9; }
.bg_color4 { background: #CFD0D3; }
.bg_grayF7  { background: #F7F7F7; }
.bg_gray1C  { background: #1C1C1C; }
.bg_grayEA  { background: #EAEAEA; }
.bg_grayF2  { background: #F2F2F2; }
.bg_grayD5  { background: #D5D5D5; }
.bg_grayF5  { background: #F5F5F5; }
.bg_grayF4  { background: #F4F4F4; }
.bg_red     { background: #ca2026; }
.bg_green   { background: #28a745; }
.bg_gradient { background: rgb(253,160,18); background: linear-gradient(180deg, rgba(253,160,18,1) 0%, rgba(253,194,18,1) 100%);}

.bg_white_hover:hover  { background: #ffffff; }
.bg_black_hover:hover  { background: #000000; }
.bg_color1_hover:hover { background: #3C3C3C; }
.bg_color2_hover:hover { background: #FDBA12; }
.bg_color3_hover:hover { background: #A8ACB9; }
.bg_color4_hover:hover { background: #CFD0D3; }

// Tamanhos padrão -------------------------------------------------------------------------------------------------
$pppp:  10px;
$ppp:  12px;
$pp:   14px;
$p:    16px;
$m:    18px;
$g:    20px;
$gg:   24px;
$ggg:  28px;
$x:    30px;
$xx:   32px;
$xxx:  36px;
$xxxx: 56px;

.font_pppp  { font-size: $pppp; }
.font_ppp  { font-size: $ppp; }
.font_pp   { font-size: $pp; }
.font_p    { font-size: $p; }
.font_m    { font-size: $m; }
.font_g    { font-size: $g; }
.font_gg   { font-size: $gg; }
.font_ggg  { font-size: $ggg; }
.font_x    { font-size: $x; }
.font_xx   { font-size: $xx; }
.font_xxx  { font-size: $xxx; }
.font_xxxx { font-size: $xxxx; }
.bold      { font-weight: bold; }

// Responsividade -------------------------------------------------------------------------------------------------
@media only screen and (max-width: 1024px) { .desktop { display: none; } }
@media only screen and (min-width: 1025px) { .mobile  { display: none; } }

// Animações de hover -------------------------------------------------------------------------------------------------
.pointer { cursor: pointer; }
.underline { text-decoration: underline; }
.underline_hover:hover { text-decoration: underline; }
.transition1 { transition: 0.3s all; }
.transition2 { transition: 0.6s all; }
.transition3 { transition: 0.9s all; }
.scale1:hover { transform: scale(1.02); }
.scale2:hover { transform: scale(1.05); }
.scale3:hover { transform: scale(1.10); }

// Margins -------------------------------------------------------------------------------------------------
.no_margin { margin: 0 !important; }
.mt_auto { margin-top: auto; }
.mb_auto { margin-bottom: auto; }
.ml_auto { margin-left: auto; }
.mr_auto { margin-right: auto; }
.mb0    { margin-bottom: 0px; }
.mb5    { margin-bottom: 5px; }
.mb10   { margin-bottom: 10px; }
.mb15   { margin-bottom: 15px; }
.mb20   { margin-bottom: 20px; }
.mb25   { margin-bottom: 25px; }
.mb30   { margin-bottom: 30px; }
.mb35   { margin-bottom: 35px; }
.mb40   { margin-bottom: 40px; }
.mb50   { margin-bottom: 50px; }
.mb60   { margin-bottom: 60px; }
.mb70   { margin-bottom: 70px; }
.mb80   { margin-bottom: 80px; }
.mb90   { margin-bottom: 90px; }
.mb100  { margin-bottom: 100px; }
.mb150  { margin-bottom: 150px; }
.mt0    { margin-top: 0px; }
.mt5    { margin-top: 5px; }
.mt10   { margin-top: 10px; }
.mt15   { margin-top: 15px; }
.mt20   { margin-top: 20px; }
.mt25   { margin-top: 25px; }
.mt30   { margin-top: 30px; }
.mt35   { margin-top: 35px; }
.mt40   { margin-top: 40px; }
.mt50   { margin-top: 50px; }
.mt60   { margin-top: 60px; }
.mt70   { margin-top: 70px; }
.mt80   { margin-top: 80px; }
.mt90   { margin-top: 90px; }
.mt100  { margin-top: 100px; }
.mt150  { margin-top: 150px; }
.ml0    { margin-left: 0px; }
.ml5    { margin-left: 5px; }
.ml10   { margin-left: 10px; }
.ml15   { margin-left: 15px; }
.ml20   { margin-left: 20px; }
.ml25   { margin-left: 25px; }
.ml30   { margin-left: 30px; }
.ml35   { margin-left: 35px; }
.ml40   { margin-left: 40px; }
.ml50   { margin-left: 50px; }
.ml60   { margin-left: 60px; }
.ml70   { margin-left: 70px; }
.ml80   { margin-left: 80px; }
.ml90   { margin-left: 90px; }
.ml100  { margin-left: 100px; }
.mr0    { margin-right: 0px; }
.mr5    { margin-right: 5px; }
.mr10   { margin-right: 10px; }
.mr15   { margin-right: 15px; }
.mr20   { margin-right: 20px; }
.mr25   { margin-right: 25px; }
.mr30   { margin-right: 30px; }
.mr35   { margin-right: 35px; }
.mr40   { margin-right: 40px; }
.mr50   { margin-right: 50px; }
.mr60   { margin-right: 60px; }
.mr70   { margin-right: 70px; }
.mr80   { margin-right: 80px; }
.mr90   { margin-right: 90px; }
.mr100  { margin-right: 100px; }
.mb5p   { margin-bottom: 5%; }
.mb10p  { margin-bottom: 10%; }
.mb15p  { margin-bottom: 15%; }
.mb20p  { margin-bottom: 20%; }
.mb25p  { margin-bottom: 25%; }
.mb30p  { margin-bottom: 30%; }
.mb35p  { margin-bottom: 35%; }
.mb40p  { margin-bottom: 40%; }
.mb50p  { margin-bottom: 50%; }
.mb60p  { margin-bottom: 60%; }
.mb70p  { margin-bottom: 70%; }
.mb80p  { margin-bottom: 80%; }
.mb90p  { margin-bottom: 90%; }
.mb100p { margin-bottom: 100%; }
.mt5p   { margin-top: 5%; }
.mt10p  { margin-top: 10%; }
.mt15p  { margin-top: 15%; }
.mt20p  { margin-top: 20%; }
.mt25p  { margin-top: 25%; }
.mt30p  { margin-top: 30%; }
.mt35p  { margin-top: 35%; }
.mt40p  { margin-top: 40%; }
.mt50p  { margin-top: 50%; }
.mt60p  { margin-top: 60%; }
.mt70p  { margin-top: 70%; }
.mt80p  { margin-top: 80%; }
.mt90p  { margin-top: 90%; }
.mt100p { margin-top: 100%; }
.ml5p   { margin-left: 5%; }
.ml10p  { margin-left: 10%; }
.ml15p  { margin-left: 15%; }
.ml20p  { margin-left: 20%; }
.ml25p  { margin-left: 25%; }
.ml30p  { margin-left: 30%; }
.ml35p  { margin-left: 35%; }
.ml40p  { margin-left: 40%; }
.ml50p  { margin-left: 50%; }
.ml60p  { margin-left: 60%; }
.ml70p  { margin-left: 70%; }
.ml80p  { margin-left: 80%; }
.ml90p  { margin-left: 90%; }
.ml100p { margin-left: 100%; }
.mr5p   { margin-right: 5%; }
.mr10p  { margin-right: 10%; }
.mr15p  { margin-right: 15%; }
.mr20p  { margin-right: 20%; }
.mr25p  { margin-right: 25%; }
.mr30p  { margin-right: 30%; }
.mr35p  { margin-right: 35%; }
.mr40p  { margin-right: 40%; }
.mr50p  { margin-right: 50%; }
.mr60p  { margin-right: 60%; }
.mr70p  { margin-right: 70%; }
.mr80p  { margin-right: 80%; }
.mr90p  { margin-right: 90%; }
.mr100p { margin-right: 100%; }

// Paddings -------------------------------------------------------------------------------------------------
.no_padding { padding: 0 !important; }
.pb0    { padding-bottom: 0px; }
.pb5    { padding-bottom: 5px; }
.pb10   { padding-bottom: 10px; }
.pb15   { padding-bottom: 15px; }
.pb20   { padding-bottom: 20px; }
.pb25   { padding-bottom: 25px; }
.pb30   { padding-bottom: 30px; }
.pb35   { padding-bottom: 35px; }
.pb40   { padding-bottom: 40px; }
.pb50   { padding-bottom: 50px; }
.pb60   { padding-bottom: 60px; }
.pb70   { padding-bottom: 70px; }
.pb80   { padding-bottom: 80px; }
.pb90   { padding-bottom: 90px; }
.pb100  { padding-bottom: 100px; }
.pb150  { padding-bottom: 150px; }
.pt0    { padding-top: 0px; }
.pt5    { padding-top: 5px; }
.pt10   { padding-top: 10px; }
.pt15   { padding-top: 15px; }
.pt20   { padding-top: 20px; }
.pt25   { padding-top: 25px; }
.pt30   { padding-top: 30px; }
.pt35   { padding-top: 35px; }
.pt40   { padding-top: 40px; }
.pt50   { padding-top: 50px; }
.pt60   { padding-top: 60px; }
.pt70   { padding-top: 70px; }
.pt80   { padding-top: 80px; }
.pt90   { padding-top: 90px; }
.pt100  { padding-top: 100px; }
.pt200  { padding-top: 200px; }
.pl0    { padding-left: 0px; }
.pl5    { padding-left: 5px; }
.pl10   { padding-left: 10px; }
.pl15   { padding-left: 15px; }
.pl20   { padding-left: 20px; }
.pl25   { padding-left: 25px; }
.pl30   { padding-left: 30px; }
.pl35   { padding-left: 35px; }
.pl40   { padding-left: 40px; }
.pl50   { padding-left: 50px; }
.pl60   { padding-left: 60px; }
.pl70   { padding-left: 70px; }
.pl80   { padding-left: 80px; }
.pl90   { padding-left: 90px; }
.pl100  { padding-left: 100px; }
.pl120  { padding-left: 120px; }
.pr0    { padding-right: 0px; }
.pr5    { padding-right: 5px; }
.pr10   { padding-right: 10px; }
.pr15   { padding-right: 15px; }
.pr20   { padding-right: 20px; }
.pr25   { padding-right: 25px; }
.pr30   { padding-right: 30px; }
.pr35   { padding-right: 35px; }
.pr40   { padding-right: 40px; }
.pr50   { padding-right: 50px; }
.pr60   { padding-right: 60px; }
.pr70   { padding-right: 70px; }
.pr80   { padding-right: 80px; }
.pr90   { padding-right: 90px; }
.pr100  { padding-right: 100px; }
.pb5p   { padding-bottom: 5%; }
.pb10p  { padding-bottom: 10%; }
.pb15p  { padding-bottom: 15%; }
.pb20p  { padding-bottom: 20%; }
.pb25p  { padding-bottom: 25%; }
.pb30p  { padding-bottom: 30%; }
.pb35p  { padding-bottom: 35%; }
.pb40p  { padding-bottom: 40%; }
.pb50p  { padding-bottom: 50%; }
.pb60p  { padding-bottom: 60%; }
.pb70p  { padding-bottom: 70%; }
.pb80p  { padding-bottom: 80%; }
.pb90p  { padding-bottom: 90%; }
.pb100p { padding-bottom: 100%; }
.pt5p   { padding-top: 5%; }
.pt10p  { padding-top: 10%; }
.pt15p  { padding-top: 15%; }
.pt20p  { padding-top: 20%; }
.pt25p  { padding-top: 25%; }
.pt30p  { padding-top: 30%; }
.pt35p  { padding-top: 35%; }
.pt40p  { padding-top: 40%; }
.pt50p  { padding-top: 50%; }
.pt60p  { padding-top: 60%; }
.pt70p  { padding-top: 70%; }
.pt80p  { padding-top: 80%; }
.pt90p  { padding-top: 90%; }
.pt100p { padding-top: 100%; }
.pl5p   { padding-left: 5%; }
.pl10p  { padding-left: 10%; }
.pl15p  { padding-left: 15%; }
.pl20p  { padding-left: 20%; }
.pl25p  { padding-left: 25%; }
.pl30p  { padding-left: 30%; }
.pl35p  { padding-left: 35%; }
.pl40p  { padding-left: 40%; }
.pl50p  { padding-left: 50%; }
.pl60p  { padding-left: 60%; }
.pl70p  { padding-left: 70%; }
.pl80p  { padding-left: 80%; }
.pl90p  { padding-left: 90%; }
.pl100p { padding-left: 100%; }
.pr5p   { padding-right: 5%; }
.pr10p  { padding-right: 10%; }
.pr15p  { padding-right: 15%; }
.pr20p  { padding-right: 20%; }
.pr25p  { padding-right: 25%; }
.pr30p  { padding-right: 30%; }
.pr35p  { padding-right: 35%; }
.pr40p  { padding-right: 40%; }
.pr50p  { padding-right: 50%; }
.pr60p  { padding-right: 60%; }
.pr70p  { padding-right: 70%; }
.pr80p  { padding-right: 80%; }
.pr90p  { padding-right: 90%; }
.pr100p { padding-right: 100%; }

// Widths -------------------------------------------------------------------------------------------------
.wd100vw { width: 100vw; }
.wd100   { width: 100%; }
.wd90    { width: 90%; }
.wd87    { width: 87%; }
.wd85    { width: 85%; }
.wd80    { width: 80%; }
.wd75    { width: 75%; }
.wd70    { width: 70%; }
.wd60    { width: 60%; }
.wd65    { width: 65%; }
.wd55    { width: 55%; }
.wd50    { width: 50%; }
.wd49    { width: 49%; }
.wd47    { width: 47%; }
.wd40    { width: 40%; }
.wd35    { width: 35%; }
.wd33    { width: calc(100%/3); }
.wd30    { width: 30%; }
.wd25    { width: 25%; }
.wd20    { width: 20%; }
.wd18    { width: 18%; }
.wd15    { width: 15%; }
.wd12   { width: 12%; }
.wd10    { width: 10%; }
.wd0     { width: 0; }

// Outros -------------------------------------------------------------------------------------------------
.fa_top      { align-items: baseline; }
.fa_center   { align-items: center; }
.fa_end      { align-items: flex-end; }
.fullRound   { border-radius: 9999px; }
.bgCover     { background-size: cover; }
.bgCenter    { background-position: center center; }
.bgNoRepeat  { background-repeat: no-repeat; }
.hidden      { display: none; }
.inline      { display: inline-block; }
.block       { display: block; }
.flex        { display: flex; }
.flexRow     { display: flex; flex-direction: row; }
.flexColumn  { display: flex; flex-direction: column; }
.oneLine     { display: -webkit-box !important; -webkit-line-clamp: 1 !important; -webkit-box-orient: vertical !important; overflow: hidden !important; text-overflow: ellipsis !important; }
.twoLines    { display: -webkit-box !important; -webkit-line-clamp: 2 !important; -webkit-box-orient: vertical !important; overflow: hidden !important; text-overflow: ellipsis !important; }
.threeLines  { display: -webkit-box !important; -webkit-line-clamp: 3 !important; -webkit-box-orient: vertical !important; overflow: hidden !important; text-overflow: ellipsis !important; }
.fourLines   { display: -webkit-box !important; -webkit-line-clamp: 4 !important; -webkit-box-orient: vertical !important; overflow: hidden !important; text-overflow: ellipsis !important; }
.wrap        { flex-wrap: wrap; }
.float_right { float: right; }
.float_left  { float: left; }
.f_center    { justify-content: center; }
.f_around    { justify-content: space-around; }
.f_between   { justify-content: space-between; }
.f_even      { justify-content: space-evenly; }
.f_start     { justify-content: flex-start; }
.f_end       { justify-content: flex-end; }
.invisible   { opacity: 0; }
.faded       { opacity: 0.5; }
.relative    { position: relative; }
.absolute    { position: absolute; }
.fixed       { position: fixed; }
.text_center { text-align: center; }
.text_left   { text-align: left; }
.text_right  { text-align: right; }
.uppercase   { text-transform: uppercase; }
.no_link     { text-decoration: none !important; }
.centerVert  { vertical-align: middle; }
.topVert     { vertical-align: top; }
.container   { width: 100%; display: flex; flex-direction: row; justify-content: center; }
.content     { width: 100%; max-width: 1200px; margin-left: auto; margin-right: auto; }
img          { image-rendering: -webkit-optimize-contrast; }
.divider     { height: 3px };
.grayscale   { filter: grayscale(100%); }
ion-row, ion-col { margin: 0 !important; padding: 0 !important; }

// Animações -------------------------------------------------------------------------------------------------
.floating { animation-name: floating; animation-duration: 1.5s; animation-iteration-count: infinite; animation-timing-function: ease-in-out; }
.floating2 { animation-name: floating2; animation-duration: 1.5s; animation-iteration-count: infinite; animation-timing-function: ease-in-out; }
.floatingWithShadow { animation-name: floatingWithShadow; animation-duration: 1.5s; animation-iteration-count: infinite; animation-timing-function: ease-in-out; }
.floatingWithShadow2 { animation-name: floatingWithShadow2; animation-duration: 1.5s; animation-iteration-count: infinite; animation-timing-function: ease-in-out; }
.slide { animation-name: slide; animation-duration: 12s; animation-iteration-count: infinite; animation-timing-function: ease-in-out; }

@keyframes floating { 0% { transform: translate(0, 0px); } 50% { transform: translate(0, 3px); } 100% { transform: translate(0, 0px); } }
@keyframes floating2 { 0% { transform: translate(0, 3px); } 50% { transform: translate(0, 0px); } 100% { transform: translate(0, 3px); } }
@keyframes floatingWithShadow {
  0% {transform: translate(0, 0px);box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);}
  50% {transform: translate(0, 3px);box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.1);}
  100% {transform: translate(0, 0px);box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);}
}
@keyframes floatingWithShadow2 {
  0% {transform: translate(0, 3px);box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);}
  50% {transform: translate(0, 0px);box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.1);}
  100% {transform: translate(0, 3px);box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
  }
}
@keyframes slide { 
  0% { background-position: top left; }
  50% { background-position: bottom right; }
  100% { background-position: top left; }
}

// Inputs -------------------------------------------------------------------------------------------------
.input1 {
  height: 46px;
  line-height: 46px;
  border: 1px solid #ddd;
  padding: 0 10px;
}
.input1:focus { 
  border: 1px solid #FDBA12;
  color: #000;
}
.input2 {
  height: 46px;
  line-height: 46px;
  border: 1px solid #fff;
  padding: 0 10px;
}
.input2:focus { 
  border: 1px solid #FDBA12;
}
textarea:focus, input:focus, .no_focus { outline: none; }
.borderGold { border: 2px solid #FDBA12; }

// Popup -------------------------------------------------------------------------------------------------
.popupCtnr { box-shadow: 2px 2px 5px 8px rgba(0, 0, 0, 0.3); }
.popupMask {
  position: fixed;
  z-index: 1000;
  background: rgba(100, 100, 100, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
}

// Scrollbar ---------------------------------------------------------------------------------------------
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px gray; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc; 
  // background: #FDBA12; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc; 
  // background: #FDBA12; 
}

.round {
  border-radius: 40px;
}

@media (max-width: 768px) {
  .bg_color2.pointer.scale2.transition1.font_pp.inline.round.text_center.bold.mt30.pt15.pb15.pl40.pr40 {
    display: none;
}

.font2.mt20.font_m.white.mt5 {
  display: none;
}

.font1.font_xxxx.white.bold.mt100 {
  font-size: 22px;
  margin-top: 20%;
  margin-left: 10%;
}
}